import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Route } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material';

@NgModule({
  imports: [CommonModule, TranslateModule, MatButtonModule],
  exports: [PageNotFoundComponent],
  declarations: [PageNotFoundComponent]
})
export class PageNotFoundWithoutRouteModule {}
