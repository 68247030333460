import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Route } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found.component';
import { PageNotFoundWithoutRouteModule } from './page-not-found-without-route.module';

export const routes: Route[] = [{ path: '', component: PageNotFoundComponent }];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    PageNotFoundWithoutRouteModule
  ]
})
export class PageNotFoundModule {}
